<template>
  <span class="add-adhesion-button" v-if="hasPerm('youth.change_openinghours')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      :ok-disabled="formInvalid"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-clock"></i> Modifier les horaires d'ouverture</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="(obj.period || obj.date)" class="info-text">
        <b-row>
          <b-col cols="9">
            <div v-if="obj.date">
              <i class="fa fa-calendar-day"></i>
              Cette plage horaire s'applique seulement pour la date du {{ obj.date | dateToString }}
            </div>
            <div v-else-if="obj.period" class="info-text">
              <i class="fa fa-calendar-days"></i>
              Cette plage horaire s'applique seulement pour la période {{ obj.period.name }}
              <div v-if="date">
                Si vous modifiez ici les horaires. Ceux-ci s'appliqueront seulement pour la date du {{ date | dateToString }}
              </div>
            </div>
          </b-col>
          <b-col class="text-right">
            <div v-if="hasPerm('youth.change_openinghours')">
              <a href @click.prevent="removeCustomOpeningHours()" class="btn btn-secondary">
                <i class="fa fa-trash"></i>
                Supprimer
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="(period || date)" class="info-text">
        <b-row>
          <b-col cols="12">
            <div v-if="date">
              <i class="fa fa-calendar-day"></i>
              Si vous modifiez ici les horaires. Ceux-ci s'appliqueront seulement pour la date du {{ date | dateToString }}
            </div>
            <div v-else-if="period">
              <i class="fa fa-calendar-days"></i>
              Si vous modifiez ici les horaires. Ceux-ci s'appliqueront seulement pour la période {{ period.name }}
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row class="line">
        <b-col>
          <b-form-checkbox id="noClocking" v-model="noClocking">Pas de pointage</b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox id="fullDay" v-model="fullDay" v-if="!noClocking">Journée continue</b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox id="lunch" v-model="lunch" :disabled="fullDay" v-if="!noClocking">
            Horaires des repas
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox id="limit" v-model="limit"  v-if="!noClocking">
            Limite arrivée / départ
          </b-form-checkbox>
        </b-col>
      </b-row>
      <div v-if="!noClocking">
        <div v-if="fullDay" class="line">
          <b-row>
            <b-col>
              <b-form-group id="opening-group" label="Ouverture" label-for="opening">
                <time-picker-input v-model="obj.openingAt" id="opening" required></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col v-if="limit">
              <b-form-group id="maxArrivalAt" label="Arrivée Max" label-for="maxArrivalAt">
                <time-picker-input v-model="obj.maxArrivalAt" id="maxArrivalAt"></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col v-if="limit">
              <b-form-group id="minDepartureAt2" label="Départ Min" label-for="minDepartureAt2">
                <time-picker-input v-model="obj.minDepartureAt2" id="minDepartureAt2"></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="closing-group" label="Fermeture" label-for="closing2">
                <time-picker-input v-model="obj.closingAt2" id="closing" required></time-picker-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row class="line">
            <b-col>
              <b-form-group id="opening-group" label="Ouverture matin" label-for="opening">
                <time-picker-input v-model="obj.openingAt" id="opening" required></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col v-if="limit">
              <b-form-group id="maxArrivalAt" label="Arrivée Max" label-for="maxArrivalAt">
                <time-picker-input v-model="obj.maxArrivalAt" id="maxArrivalAt"></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col v-if="limit">
              <b-form-group id="minDepartureAt" label="Départ Min" label-for="minDepartureAt">
                <time-picker-input v-model="obj.minDepartureAt" id="minDepartureAt"></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="closing-group" label="Fermeture matin" label-for="closing">
                <time-picker-input v-model="obj.closingAt" id="closing" required></time-picker-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="lunch" class="line">
            <b-col v-if="limit"></b-col>
            <b-col>
              <b-form-group id="lunch-start-group" label="Début de repas" label-for="lunch-start">
                <time-picker-input v-model="obj.lunchStartsAt" id="lunch-start" required></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="lunch-end-group" label="Fin de repas" label-for="lunch-end">
                <time-picker-input v-model="obj.lunchEndsAt" id="lunch-end" required></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col v-if="limit"></b-col>
          </b-row>
          <b-row class="line">
            <b-col>
              <b-form-group id="opening2-group" label="Ouv. après-midi" label-for="opening2">
                <time-picker-input v-model="obj.openingAt2" id="opening2"></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col v-if="limit">
              <b-form-group id="maxArrivalAt2" label="Arrivée Max" label-for="maxArrivalAt2">
                <time-picker-input v-model="obj.maxArrivalAt2" id="maxArrivalAt2"></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col v-if="limit">
              <b-form-group id="minDepartureAt2" label="Départ Min" label-for="minDepartureAt2">
                <time-picker-input v-model="obj.minDepartureAt2" id="minDepartureAt2"></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="closing2-group" label="Ferm. après-midi" label-for="closing2">
                <time-picker-input v-model="obj.closingAt2" id="closing2"></time-picker-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="line">
            <b-col>
              <b-form-group id="opening3-group" label="Ouv. soir" label-for="openingAt3">
                <time-picker-input v-model="obj.openingAt3" id="openingAt3"></time-picker-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="closing3-group" label="Ferm. soir" label-for="closingAt3">
                <time-picker-input v-model="obj.closingAt3" id="closingAt3"></time-picker-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col>
            <b-form-group
              label-for="range-step"
              label="Pointage"
              :description="'Ajoute ou enlève ' + obj.step + ' minutes à chaque clic'"
            >
              <div><b>Pas</b></div>
              <b-form-input
                id="range-step"
                name="range-step"
                v-model="obj.step"
                type="number"
                min="5"
                max="30"
                step="5"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label-for="range-direct-ceil"
              label="Pointage direct"
              :description="directCeilDescription"
            >
              <b-row>
                <b-col>
                  <div><b>Plafond</b></div>
                  <b-form-input
                    id="range-direct-ceil"
                    name="range-direct-ceil"
                    v-model="obj.directCeil"
                    type="number"
                    min="0"
                    max="60"
                    step="5"
                  >
                  </b-form-input>
                </b-col>
                <b-col>
                  <div><b>Limite</b></div>
                  <b-form-input
                    id="range-direct-limit"
                    name="range-direct-limit"
                    v-model="obj.directLimit"
                    type="number"
                    min="0"
                    :max="obj.directCeil"
                    step="1"
                    :disabled="obj.directCeil === 0"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label-for="commonArrivalAndDeparture"
              description="Coche automatiquement le pointage correspondant"
              v-if="!fullDay"
            >
              <b-form-checkbox
                id="commonArrivalAndDeparture"
                v-model="obj.commonArrivalAndDeparture"
              >
                Arrivée et départ en commun
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label-for="fixedLunch"
              description="Pour les repas, les heures de départ matin et arrivée après-midi sont celles des repas"
              v-if="lunch"
            >
              <b-form-checkbox
                id="fixedLunch"
                v-model="obj.fixedLunch"
              >
                Départ et arrivée aux heures des repas
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import TimePickerInput from '@/components/Controls/TimePickerInput'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeOpeningHours } from '@/types/youth'
import { dateToString } from '@/filters/texts'

export default {
  name: 'OpeningHoursModal',
  mixins: [BackendMixin],
  components: {
    TimePickerInput,
  },
  props: {
    modalId: String,
    youthHome: Object,
    seanceType: Object,
    period: {
      type: Object,
      default: null,
    },
    date: String,
  },
  data() {
    return {
      fullDay: false,
      lunch: true,
      limit: true,
      noClocking: false,
      errorText: '',
      obj: makeOpeningHours({}),
    }
  },
  computed: {
    onlyPeriod() {
      if (!this.onlyDate) {
        return this.obj.period || this.period
      }
      return null
    },
    onlyDate() {
      return this.obj.date || this.date
    },
    formInvalid() {
      if (this.noClocking) {
        return false
      } else {
        if (this.obj.maxArrivalAt && this.obj.maxArrivalAt < this.obj.openingAt) {
          return (
            (this.obj.openingAt === null) ||
            (this.obj.closingAt2 === null) ||
            (this.obj.maxArrivalAt < this.obj.openingAt)
          )
        } else if (this.obj.minDepartureAt && this.obj.minDepartureAt > this.obj.closingAt2) {
          return (
            (this.obj.openingAt === null) ||
            (this.obj.closingAt2 === null) ||
            (this.obj.minDepartureAt2 > this.obj.closingAt2)
          )
        } else {
          return (this.obj.openingAt === null) || (this.obj.closingAt2 === null)
        }
      }
    },
    directCeilDescription() {
      if (this.obj.directCeil > 0) {
        return 'Pointage direct arrondi aux ' + this.obj.directCeil +
          ' minutes supérieures à partir de ' + this.obj.directLimit + ' minutes'
      } else {
        return 'Pointage direct arrondi à ' + this.obj.step + ' minutes'
      }
    },
  },
  watch: {
    step() {
      this.obj.step = +this.obj.step
      if (this.obj.step < 5) {
        this.obj.step = 5
      } else if (this.obj.step > 30) {
        this.obj.step = 30
      }
    },
    directCeil() {
      this.obj.directCeil = +this.obj.directCeil
      if (+this.obj.directLimit > this.obj.directCeil) {
        this.obj.directLimit = this.obj.directCeil
      }
    },
    directLimit() {
      this.obj.directLimit = +this.obj.directLimit
      if (this.obj.directLimit > +this.obj.directCeil) {
        this.obj.directLimit = +this.obj.directCeil
      }
    },
    fullDay: function() {
      if (this.fullDay) {
        this.obj.lunch = false
        this.obj.closingAt = null
        this.obj.openingAt2 = null
        this.obj.commonArrivalAndDeparture = false
      }
    },
    lunch: function() {
      if (!this.lunch) {
        this.obj.lunchStartsAt = null
        this.obj.lunchEndsAt = null
      }
    },
    limit: function() {
      if (!this.limit) {
        this.obj.maxArrivalAt = null
        this.obj.minDepartureAt = null
        this.obj.maxArrivalAt2 = null
        this.obj.minDepartureAt2 = null
      }
    },
    noClocking: function() {
      if (this.noClocking) {
        this.obj.openingAt = null
        this.obj.closingAt = null
        this.obj.openingAt2 = null
        this.obj.closingAt2 = null
        this.obj.openingAt3 = null
        this.obj.closingAt3 = null
        this.obj.lunchStartsAt = null
        this.obj.lunchEndsAt = null
        this.obj.maxArrivalAt = null
        this.obj.minDepartureAt = null
        this.obj.maxArrivalAt2 = null
        this.obj.minDepartureAt2 = null
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    async init() {
      await this.getOpeningHours()
      this.initCheckBoxes()
    },
    isSet(data) {
      if (data === '') {
        data = null
      }
      return data
    },
    async onSave() {
      this.errorText = ''
      if (this.youthHome && this.seanceType) {
        let url = '/api/youth/change-opening-hours/' + this.youthHome.id + '/' + this.seanceType.id + '/'
        let backendApi = new BackendApi('patch', url)
        const data = {
          opening_at: this.isSet(this.obj.openingAt),
          closing_at: this.isSet(this.obj.closingAt),
          opening_at2: this.isSet(this.obj.openingAt2),
          closing_at2: this.isSet(this.obj.closingAt2),
          opening_at3: this.isSet(this.obj.openingAt3),
          closing_at3: this.isSet(this.obj.closingAt3),
          lunch_starts_at: this.isSet(this.obj.lunchStartsAt),
          lunch_ends_at: this.isSet(this.obj.lunchEndsAt),
          morning_max_arrival: this.isSet(this.obj.maxArrivalAt),
          morning_min_departure: this.isSet(this.obj.minDepartureAt),
          afternoon_max_arrival: this.isSet(this.obj.maxArrivalAt2),
          afternoon_min_departure: this.isSet(this.obj.minDepartureAt2),
          common_arrival_and_departure: !!this.obj.commonArrivalAndDeparture,
          fixed_lunch: !!this.obj.fixedLunch,
          step: this.obj.step,
          direct_limit: this.obj.directLimit,
          direct_ceil: this.obj.directCeil,
        }
        let successMessage = 'Les horaires d\'ouvertures ont été modifiés'
        if (this.period) {
          data['period'] = this.period.id
          const onlyDate = this.date || this.obj.date
          if (onlyDate) {
            data['date'] = dateToString(onlyDate, 'YYYY-MM-DD')
            let day = dateToString(onlyDate, 'lll')
            successMessage = 'Les horaires d\'ouvertures de la journée du ' + day + ' ont été modifiés'
          } else {
            successMessage = 'Les horaires d\'ouvertures de la période ' + this.period.name + ' ont été modifiés'
          }
        }
        try {
          const resp = await backendApi.callApi(data)
          this.$bvModal.hide(this.modalId)
          this.$emit('refresh')
          await this.addSuccess(successMessage)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async removeCustomOpeningHours() {
      this.errorText = ''
      if (this.youthHome && this.seanceType && this.obj && this.obj.period) {
        let url = '/api/youth/custom-opening-hours/'
        url += this.youthHome.id + '/'
        url += this.seanceType.id + '/'
        const data = {
          period: this.obj.period.id,
          date: this.obj.date ? dateToString(this.obj.date, 'YYYY-MM-DD') : null,
        }
        let backendApi = new BackendApi('delete', url)
        try {
          await backendApi.callApi(data)
          await this.addSuccess('Les horaires ont été supprimés')
          this.$bvModal.hide(this.modalId)
          this.$emit('refresh')
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async getOpeningHours() {
      this.errorText = ''
      if (this.youthHome && this.seanceType) {
        let url = '/api/youth/get-opening-hours/'
        url += '?youth_home=' + this.youthHome.id + '&seance_type=' + this.seanceType.id
        if (this.period) {
          url += '&period=' + this.period.id
        }
        if (this.date) {
          url += '&date=' + dateToString(this.date, 'YYYY-MM-DD')
        }
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.obj = makeOpeningHours(resp.data)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    initCheckBoxes() {
      this.noClocking = !this.obj.openingAt && !this.obj.closingAt2 && !this.obj.closingAt
      this.fullDay = !this.obj.closingAt && !this.obj.openingAt2
      this.lunch = !(!this.obj.lunchStartsAt && !this.obj.lunchEndsAt)
      this.limit = !(
        !this.obj.maxArrivalAt &&
        !this.obj.maxArrivalAt2 &&
        !this.obj.minDepartureAt &&
        !this.obj.minDepartureAt2
      )
    },
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #ad4a25;
    background: #e0e0e0;
  }
  .line {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: #ccc 1px solid;
  }
</style>
